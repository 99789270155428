import React from "react";
import { workshops, livestreams, collaborations } from '../const/urlKeys';
import { isAndroid, isIOS, isChrome } from 'react-device-detect';
import moment from 'moment';

import { dats3Letters } from '../const/calendar';
import {AllTiers} from "../const/tiers";
import {statusEnded} from "../const/general";
import {audio, video} from "../const/fileSettings";
import {typeToUrl} from "../const/typeList";

export const getToken = () => localStorage.getItem('session_token') || '';

export const getLabel = path => { // (community, path)
  // const currentCommunity = community ? community.toLowerCase() : '';
  switch (path) {
    case workshops:
      return 'Groups'
    // return  ['fitness', 'family', 'spiritual', 'lifestyle' ].includes(currentCommunity) ?
    //   'Coaching' :  "Workshops"
    case livestreams:
      return "Live Stream"
    case collaborations:
      return 'Groups'
    // return  ['recovery','family'].includes(currentCommunity) ?
    //   'Groups' : "Collaborations"

    default:
    return
  }
}

export const redirectToAPP = (url, iosUrl = false) => {
  if (isAndroid) {
    window.location.replace(url);

    const clickedAt = +new Date;
    setTimeout(function() {
      if (+new Date - clickedAt < 2000) {
        window.location.replace("https://play.google.com/store/apps/details?id=com.doneit.ascend");
      }
    }, 500);
  }

  if (isIOS && isChrome) {
    iosUrl ? window.open(iosUrl) : window.open(url);

    const clickedAt = +new Date;
    setTimeout(function() {
      if (+new Date - clickedAt < 2000) {
        window.open("https://apps.apple.com/ua/app/ascendvideo/id1496739998");
      }
    }, 500);
  }

  if (isIOS && !isChrome) {
    try {
      if (iosUrl) {
        window.location.replace(iosUrl);
      } else {
        window.location.replace(url);
      }
      window
        .then(data => console.log('then'))
        .catch(error => console.log('errror'))

    } catch (error) {
      window.location.href = "https://apps.apple.com/ua/app/ascendvideo/id1496739998";
    }
  }
}

export const disableBodyScroll = (isDisabled) => {
  if(isDisabled){
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
  } else {
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }
}

export const timeToStartMetting = time => {
  const nowMoment = moment();
  const startEvetnMoment = moment(time);
  return startEvetnMoment.diff(nowMoment, 'minutes');
}


export const parseSocketMessage = (message) => {
  const data = JSON.parse(message.data);

  //filter ping messages
  if(data.type === "ping"){
    return;
  }
  return data.message;
}


export const isValidURL = (str) => {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

export const scheduleStrToObj = (str) => {
  const res = !!str ? str : '';
  const strToArr = res.split(', ');
  const timeData = strToArr[strToArr.length-1];

  let daysVal = [];
  if (strToArr.length > 1) {
    daysVal = strToArr.slice(0, strToArr.length-1);
  }
  let time = timeData;
  if (timeData.length > 10) {
    daysVal.push(timeData.substring(0,3));
    time = timeData.substring(4, timeData.length);
  }
  const days = [];
  daysVal.forEach(day => days.push(dats3Letters.indexOf(day)));

  return ({ time, days })
}

export const getWeekDiffs = ({day, time}) => {
  const weekMinutesDuration = 7*24*60;
  const mondayZero = moment().utcOffset(0).weekday(1).set({
    hour: 0,
    minute: 0,
    second: 0
  })

  const hour =  moment(time, 'hh:mm a').format("HH")
  const minute =  moment(time, 'hh:mm a').format("mm")
  const currentTime = moment().weekday(day).set({
    hour,
    minute,
    second: 0
  })

  let diff = currentTime.diff(mondayZero, 'minutes')
  if (diff < 0){
    diff += weekMinutesDuration
  }
  return diff
}

export const getWeekdayInfo = (day, returnIndex = false) => {
  const dayIndex = typeof day === 'string' ? dats3Letters.findIndex(dat => dat === day) : day
  if (dayIndex !== -1) {
    return returnIndex ? dayIndex : dats3Letters[dayIndex]
  }
}

export const returnName = (user) => {
  if (user?.display_name) {
    return user?.display_name
  }
  return user?.full_name
}

export const toggleHelpButton = bool => {
  const element = document.getElementById('answers-widget-launcher-container');
  if (element) {
    element.hidden = bool;
  }
};

export const daysCountInPeriod = (momentStart, momentEnd, daysIndexArr) => {
  let daysData = {}
  // const format = 'DD-MM-YYYY hh:mm a'
  daysIndexArr.forEach( (d_index) => {
    let t_start = momentStart.clone().day(d_index)
    daysData[d_index] = 0
    if( t_start.isBefore(momentStart) ){
      t_start.add(7, 'days');
    }
    while( t_start.isBefore(momentEnd) ){
      daysData[d_index] = ++daysData[d_index]
      t_start.add(7, 'days');
    }
  })

return Object.values(daysData).reduce((i, t) => t + i, 0)

}

export const findIndexInArrOfObj = (arr, id) => {
  let index = -1
  arr.find((item, i) => {
    if (id === item.id){
      index = i
      return true;
    }
  })
  return index
}

export const findTiersTitleByName = (id) => {
  return AllTiers.name[id] || ''
}

export const getCorrectPrice = (price) => {
  if (!price) return 0;
  if (typeof price === "number"){
    return  parseFloat(price) * 100
  }
  return Math.round(parseFloat(price.replace(/,/g, '.')) * 100)
}


export const neatTime = (time) => {
  var minutes = Math.floor((time % 3600)/60);
  var seconds = Math.floor(time % 60);
  minutes = minutes > 9 ? minutes : `0${minutes}`
	seconds = seconds > 9 ? seconds :`0${seconds}`;
	return `${minutes}:${seconds}`;
}

export const neatTimeFull = (time) => {
  var hours = Math.floor((time / 3600));
  const neatTimeStr = neatTime(time);

  if(hours === 0 ){
    return neatTimeStr
  }

  hours = hours > 9 ? hours : `0${hours}`;
	return `${hours}:${neatTimeStr}`;;
}


export const getLsRecordsTotalViews = (records) => {
  return records.reduce((acc, current) => acc + current.views_count, 0);
}


export const getPlaylistMeta = (playlistItems) => {
  const data = playlistItems.reduce(
    (acc, current) => {
      acc.videos += current.file_type === "audio" ? 0 : 1;
      acc.audios += current.file_type === "audio" ? 1 : 0;
      acc.seconds += current.duration;
      return acc;
    },{ videos: 0, audios: 0, seconds: 0 }
  );

  const timeStr = data.seconds >= 60 ? `${Math.floor(data.seconds / 3600)}h ${Math.floor(data.seconds / 60)}m`: `0m ${data.seconds}s`;
  return `${data.videos} videos, ${data.audios} audios • ${timeStr}`;
};

export const getRecordFileType = (record) => {
  if (record.file_type) return record.file_type;
  const format = record.file && record.file.slice(record.file.lastIndexOf('.'))
  if (!format) return undefined;
  if (video.includes(format.toLowerCase())) return 'video'
  if (audio.includes(format.toLowerCase())) return 'audio'
}

export const detectUploadFileType = (file) => {
  const type = file.type

  if (type){
    if (type.includes('video')) return 'video';
    if (type.includes('audio')) return 'audio';
  }
  const format = file.name.slice(file.name.lastIndexOf('.'))
  if (format) {
    if (video.includes(format.toLowerCase())) return 'video'
    if (audio.includes(format.toLowerCase())) return 'audio'
  }
}

export const  generateShareLinkStart = (item) => {
  const type = item.record_type || item.group_type
  return typeof typeToUrl[type] === "string"? typeToUrl[type] : typeToUrl[type][item.status === statusEnded? item.status : 'DEFAULT']
}

export const checkCommunityIncludeInFilters = (item, filters) => {
  if(!item?.communities){return filters}
  return item.communities.some((c_id) => filters.includes(+c_id.id))
}

/**
 * daysArr: Array of digits
 * time: string hh:mm
 * */
const toRealTime = (daysArr, time) =>{
  const daysMoment = daysArr.map( day => moment().zone(0)
    .set({
      day: day,
      hour:parseInt(time.split(':')[0]) ,
      minute:parseInt(time.split(':')[1]) ,
    }).utcOffset(moment().utcOffset())
  )
  return {
    time: moment(daysMoment[0]).format('hh:mm a')
  }
}

const sortedByDays = timeData => Object.entries(timeData).sort((curr, next) => {
  let currDay = curr[1].sort()
  let nextDay = next[1].sort()
  return currDay && nextDay && currDay[0] - nextDay[0]
})

export const mitingsDateList = (item) => {
  const date = moment(item.start_time);
  if (!((item.wdays || item.week_diffs) && item.start_time)) {
    const days = date.format('ddd');
    const time = date.format('hh:mm a');
    return (
      <div key={`${time}-`}>
        {`${days}, ${time}`}
      </div>
    );
  }

  if (item.week_diffs){
    const schedule = {}
    item.week_diffs.forEach((minDiff, key) => {
      const momentTime = moment()
        .weekday(1)
        .zone(0)
        .set({
          hour: 0,
          minute: 0,
          second: 0
        })
        .set({minutes: minDiff})
        .utcOffset(moment().utcOffset())
      const time = momentTime.format("hh:mm a") || "01:00 am"
      const day = momentTime.format('ddd')
      if (schedule[time]){
        schedule[time].push(day)
      } else {
        schedule[time] = [day]
      }
    })

    return sortedByDays(schedule).map(([time, day]) => {
      const days = schedule[time].sort().reverse().map(wday => `${moment().day(wday).format('ddd')} `);
      return (
        <div key={`${time}-`}>
          {`${days}, ${time}`}
        </div>
      );
    })
  } else if (item.times) {
    let timeData = {};
    item.times.forEach((time, index) => {
      const wday = item.wdays[index];

      if (index < item.meetings_count) {
        if (timeData[time]) {
          timeData[time].push(wday)
        } else {
          timeData = { ...timeData, [time]: [wday] }
        }
      }
    });


    return sortedByDays(timeData).map(([time, day]) => {
      const days = timeData[time].sort().map(wday => `${moment().day(wday).format('ddd')} `);
      const realData = toRealTime(timeData[time].sort(), time)
      return (
        <div key={`${time}-`}>
          {`${days}, ${realData.time}`}
        </div>
      );
    });
  } else if (item.wdays){
    const days = item.wdays?.map(wday => `${moment().day(wday).format('ddd')} `);
    const realData = toRealTime(item.wdays, date.zone(0).format('HH:mm'))
    return (
      <div key={`${realData.time}-`}>
        {`${days}, ${realData.time}`}
      </div>
    );
  }
}
export const checkIsUserMM = (userData, communityList, communityId = userData?.community_id) => {
  return userData?.community_id && communityList.count > 0 && communityList.mappedCommunities[communityId]?.moderator
}

export const checkIsUserVerifyMM = (userData, communityList, communityId = userData?.community_id) => {
  return checkIsUserMM(userData, communityList, communityId) && userData?.stripe_required_fields_filled && userData.payouts_enabled
}